@media (min-width: 768px) and (max-width: 979px){
.locales {
    padding-top: 8px;
}
.header-call {
    padding-left: 130px;
}
.locale {
    font-size: 13px;
}
.header-call p span {
    margin-right: 10px;
}
.header-social ul{
    padding-top: 0px;
}
.header-social ul li a {
    font-size: 12px;
}
.navbar {
    min-height: 73px;
}
.navbar-brand img {
    width: 110px;
    height: 105px;
}
.navbar-default .navbar-nav > li > a {
    font-size: 15px;
}
.nav-button {
    font-size: 13px;
    padding: 6px 13px;
}
.navbar-nav > li > a {
    padding-left: 6px;
    padding-right: 6px;
}
.main-nav {
    padding-top: 12px;
}
.navbar-default .navbar-nav > li > a.active::after {
    bottom: -10px;
    height: 4px;
    width: 100%;
}
.navbar-default .navbar-nav > li > a::after{
    bottom: -10px;
    height: 4px;
}
.register-area .col-md-6 {
    margin-bottom: 15px;
}
.slider-content {
    margin-left: 0px;
    padding: 35px;
    top:10%;
}
.slider-content h2 {
    font-size: 30px;
    margin-bottom: 15px;
}
.slider-content p {
    font-size: 12px;
    margin-bottom: 50px;
}
.search-form {
    padding: 15px 0;
}
.form-inline .form-control, .form-inline .form-control {
    width: 100%;
}
.slider-area{
    height:440px; 
}    
.content-area { 
    padding-top: 15px;
}
.search-form .form-inline .form-group {
    width: 195px; 
}
#bg-slider .item img {
    height: 440px;
}
.slider .owl-pagination {
    bottom: 165px;
    right: 0;
    width: 30px;
}
.page-title{
    padding-top: 50px;
}
.page-title h2 {
    font-size: 20px;
    margin-top: 2px;
}
.page-title h5 {
    font-size: 12px;
}
.page-title p {
    line-height: 20px;
    margin-top: 10px;
    font-size: 12px;
}
.tab-content ul li {
    height: 235px;
    margin-bottom: 20px;
    width: 219px;
}
.tab-content ul li a img {
    height: 100%;
    width: 100%;
}
.pr0 {
    padding-right: 15px !important;
}
}

@media (min-width: 969px) and (max-width: 1169px){
.locales {
    padding-top: 26px;
}
.navbar {
    min-height: 90px;
}
.main-nav {
    padding-top: 30px;
}
.navbar-nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
}
.header-social .list-inline > li{
    padding-right: 0px
}
.slider-content {
    margin-left: 0px;
}
.slider-area{
    height: 540px;
}
#bg-slider .item img {
    height: 540px;
}
.tab-content ul li {
    height: 160px;
    width: 145px;
}
.tab-content ul li a img{
    width: 100%;
    height: 100%;
}
}